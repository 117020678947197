export const faqConst = [
  {
    id: 1,
    title: "What is Pace Verso?",
    answer:
      "Pace Verso is Pace Gallery’s hub for integrated Web3 artworks, launched in 2021 under the leadership of Pace CEO Marc Glimcher. Pace Verso works closely with artists within and beyond the gallery’s program to incubate, develop, and realize their Web3 projects.",
    answerTwo:
      "Pace Verso is widely recognized for bridging the divide between the traditional art world and Web3, particularly in the generative art space. In addition to releasing independent artist projects, Pace Verso frequently collaborates with leading Web3 platforms to share artists’ work with wide audiences. In 2022, Pace Verso established a multifaceted partnership with Art Blocks, the leading generative art platform, encompassing boundary-pushing generative NFT releases by Pace’s artists, exhibitions, and community programming.",  
    answerThree: "In its first two years, Pace Verso—which reflects the gallery’s longtime and ongoing support of innovative artists who have cultivated advanced studio practices engaged with boundary-pushing technologies—has presented projects by Jeff Koons, Tyler Hobbs, Loie Hollowell, Robert Whitman, Tara Donovan, John Gerrard, Zhang Huan, Lucas Samaras, and other artists. Pace Verso has also grown a robust following on its dedicated Discord server, directly engaging Web3 communities through discussions with artists and leaders in the crypto space.",  
    linkThree: "https://www.pacegallery.com/pace-verso/",
    Continue: " for more information on Pace Verso.",
    linkText: "Click here",
  },
  {
    id: 2,
    title: "How do I join the Pace Verso community?",
    answer: "Join our Discord ",
    link: "https://discord.gg/paceverso",
    Continue:
      " for access to exclusive perks and early announcements and information about upcoming drops.",
    linkText: "here",
  },
  {
    id: 3,
    title: "What is an NFT?",
    answer:
      "Non-Fungible tokens, or NFTs, represent unique digital items that are stored on a blockchain. They cannot be replicated.",
  },
  {
    id: 4,
    title:
      "What is generative art?",
    answer:
      "Generative art refers to artworks that are generated based on a set of parameters and permutations that an artist has defined – resulting in a set of works which are all unique, but clearly part of the same series by the same artist. For example,",
    link: "https://www.pacegallery.com/journal/loie-hollowell-contractions/",
    Continue:
      ", based on her Split Orb sculptural paintings.",
    linkText: " Loie Hollowell’s generative art project, Contractions",
  },
  {
    id: 5,
    title:
      "What steps do I need to take before making a purchase on the Pace Verso platform?",
    answer:
      "You must complete your profile, which includes a mandatory Know Your Customer (KYC) identity verification process, before you can acquire an artwork. Please proceed to your Account page to complete your profile.",
  },
  {
    id: 6,
    title: "Why do I need to complete my profile?",
    answer: `You must complete your profile in order for us to perform a mandatory Know Your Customer (KYC) identity verification process. This is required before you can acquire an artwork. Please proceed to your Account page to complete your profile.`,
  },
  {
    id: 7,
    title: "Why is my profile still under review?",
    answer:
      "Our team is currently reviewing your details. This may take up to 24 hours. You will see a green checkmark on your profile page once approved. Please reach out to support if verifications is pending for more than 15 minutes. If there are any issues with your verification, they will be addressed 9am-5pm EST Monday-Friday.",
  },
  {
    id: 8,
    title: "How do I pay for an NFT from Pace Verso?",
    answer:
      "Pace Verso offers credit card and cryptocurrency payment options. However, please note that payment options may vary for each Pace Verso project.",
  },
  {
    id: 9,
    title:
      "Can I purchase more than one artwork from a release?",
    answer:
      "Yes – there is no limit to the number of artworks you can purchase. However, you will have to complete a purchase process individually for each artwork (you can’t purchase multiple in one transaction).",
  },
  {
    id: 10,
    title:
      "Do I need to set up a cryptocurrency wallet in order to purchase from Pace Verso?",
    answer:
      "Most Pace Verso projects do not require a cryptocurrency wallet to purchase (for example, Maya Lin’s Ghost Forest Seedlings and CP1919: Sweeping Sun White 2023 and Sweeping Sun Black 2023). In these cases, you do not need to set up your own cryptocurrency wallet to purchase an NFT. Instead, Pace Verso can provide you with an “email wallet” to hold your artworks/NFTs within your Pace Verso online account.",
  },
  {
    id: 11,
    title:
      "What sales tax will I need to pay?",
    answer:
      "Pace Verso is required by law to collect sales tax based on where you reside. Sales tax (if applicable) will be calculated based on your billing address at the point of purchase.",
  },
  {
    id: 12,
    title: "Where can I see the artworks that I have purchased?",
    answer:
      "If you elect to have your NFT transferred directly to your own cryptocurrency wallet, then it will be transferred there once payment confirmation is received. Otherwise, it will be held in the email wallet you created with Pace Verso, which you can view on your Account Page. Note that you will need to click “Connect Wallet” at the top right to connect your wallet and view your artworks.  From there, you will be able to transfer it to your cryptocurrency wallet at any time.",
  },
  {
    id: 13,
    title: "I paid with a credit card - what should I expect after completing my purchase and when will I receive my artwork?",
    answer:
      `It can take up to 24 hours for your payment to go through, after which your NFT will automatically be transferred to the wallet you submitted during checkout.`,
  },
  {
    id: 14,
    title: "I paid with cryptocurrency - what should I expect after completing my purchase and when will I receive my artwork?",
    answer:
      `If you elected to have your NFT transferred directly to your own non-custodial wallet (e.g. MetaMask), then it will be transferred there once payment confirmation is received. Otherwise, it will be transferred to your email wallet.`,
  },
  {
    id: 15,
    title: "I paid - why can’t I see my NFT?",
    answer:
      `Please read the responses above based on your payment method. Network congestion can prolong the amount of time your NFT takes to show up in your wallet. If you paid with cryptocurrency and you don’t see your NFT after 10 minutes, double check that you sent your NFT to the correct wallet address. If you’re still having trouble seeing your NFT, please reach out to our support team at `,
    link: "mailto:paceversosupport@getmojito.com",
    linkText: "paceversosupport@getmojito.com."  
  },
  {
    id: 16,
    title: "What happens if I forget my password?",
    answer:
      `In the event that you forget your password, press “Forgot password?” on the account log-in page. An email will be sent to your inbox, if there is an account associated with the information provided. Locate the “Reset password” button within the email and follow the prompts to reset your password.`,
  },
  {
    id: 17,
    title: "How does the CP1919: Sweeping Sun Black 2023 auction work?",
    list: [
      {
        name: "To bid in the CP1919: Sweeping Sun Black 2023 auction, you must create an account and complete your profile. Please see FAQs above for further details on how to complete your profile.",
      },
      {
        name: "Bids can be placed in increments of $50. The maximum amount you can bid is $450 over the previous bid. ",
      },
      {
        name: "You will be notified via email if you are outbid.",
      },
      {
        name: "The auction is scheduled to close on October 13 at 4:00pm EST. Any bids placed after 3:55pm on October 13 will trigger an auction extension of 5 minutes. These extensions can continue up until 6:00pm EST. At that time, or earlier if there are no further extensions, the auction will end.",
      },
    ]
  },
  {
    id: 18,
    title: "Can I claim a physical item associated with my artwork if I purchase through a secondary marketplace?",
    list: [
      {
        name: "Before purchasing an artwork on a secondary marketplace, check if that work has a “trait” called “Claimed” or “Unclaimed.” If the trait is “Claimed,” the physical item associated with that artwork has already been claimed, and future buyers will not be able to claim it again. If it has the trait “Unclaimed,” the physical item associated with that artwork has not been claimed, and a future buyer will be able to claim it."
      },
      {
        name: 'If you purchase a work with the "Unclaimed" trait, you can proceed to claim it by visiting: ',
        link: "https://versoclaim.pacegallery.com",
        linkText: "versoclaim.pacegallery.com.",
      },
      {
        name: "Once you’re on the claim website, use the search tool to find the artwork that you own. In the case of Ghost Forest Seedlings and CP1919: Sweeping Sun White 2023, you will need to search for the token ID or number in the artwork title. Once you find the number associated with your artwork, you can click the “Connect Wallet” button, which is adjacent to the artwork or product image. "
      },
      {
        name: "For further details on the subsequent steps, please refer to the FAQ question below titled “What is token gating, and how does the token gate work on the Pace Verso claim website?”"
      },
    ]
  },
  {
    id: 19,
    title: "How do I transfer my artwork from my email wallet to my crypto wallet?",
    list: [
      {
        name: "If you purchased your artwork by creating an email wallet, and you wish to your artwork into your crypto wallet, you can follow these steps:",
        list: ["Visit <a href='https://withpaper.com/' target='_blank'>withpaper.com</a>", 
        "Click “My Wallet” on the top right of the webpage",
        "Log into your wallet using the same email you used to create a wallet on <a href='https://verso.pacegallery.com/' target='_blank'>verso.pacegallery.com</a>. This will trigger a 6 digit verification code being sent to your email",
        "Go to your email to find the code ",
        "Input the code on <a href='https://withpaper.com/' target='_blank'>withpaper.com</a>, and a dashboard containing your artwork(s) will appear",
        "Click on the artwork that you want to transfer",
        'A pop up will appear. Click on the "Transfer" button, which can be found in the lower right corner ',
        "Enter the wallet address where you want to send the artwork",
        "After your transfer has been successfully completed, the pop-up will close and a green message will appear notifying you that your transfer has been completed",
      ]
      },
      {
        name: "If you run into any issues throughout this process, please contact our technical support team by emailing",
        link: "mailto:paceversosupport@getmojito.com.",
        linkText: " paceversosupport@getmojito.com.",
      },
    ]
  },
  {
    id: 20,
    title: "Are there any fees associated with claiming a physical item associated with my artwork?",
    answer:
      `You will be required to pay for shipping, duties, and import taxes (if applicable).`,
  },
  {
    id: 21,
    title:
      "How do I claim my Ghost Forest Seedlings print or CP1919: Sweeping Sun White 2023 t-shirt?",
    answer:
      "You will claim your Ghost Forest Seedling print and CP1919: Sweeping Sun White 2023 t-shirt through the Pace Verso claim website. You will proceed through a token gating process that ensures you are the owner of the artwork you are claiming. Please find FAQs below for additional information regarding this token gating process.",
    answerTwo: "The print claim period is now open  for Ghost Forest Seedlings. It will open the week of October 16 for CP1919. You will receive an email with the link to claim. Please reach out to",
    linkTwo: "mailto:paceversosupport@getmojito.com",
    ContinueTwo: " if you require further support.",
    linktTwoText: "paceversosupport@getmojito.com "
  },
  {
    id: 22,
    title: "What is token gating, and how does the token gate work on the Pace Verso claim website?",
    list: [
      {
        name: "Token gating ensures you are the owner of the print you are claiming.",
      },
      {
        name: "The following 5 steps outline the token gating process on the Pace Verso claim website:",
      },
      {
        name: "​​Step 1) After purchasing your artwork, you will receive a confirmation email with a link to claim your Ghost Forest Seedlings print or CP1919: Sweeping Sun White 2023 t-shirt. Click the link within the email to be directed to the",
        link: "https://versoclaim.pacegallery.com/",
        linkText: " Pace Verso claim website."
      },
      {
        name: "If you do not receive this email, please reach out to ",
        link: "mailto:paceversosupport@getmojito.com",
        linkText:'paceversosupport@getmojito.com.'
      },
      {
        name: "Step 2) Once you arrive at the Pace Verso claim website, click the “Connect wallet” button located to the right of the artwork."
      },
      {
        name: "Step 3) If your digital artwork is stored in a Pace Verso account, select “Email Wallet” and enter the email you used to create an account on ",
        link: "https://verso.pacegallery.com",
        linkText:'verso.pacegallery.com.',
      },
      {
        name: "If your digital artwork is stored in a crypto wallet, please use the same wallet you used to purchase your artwork on ",
        link: "https://verso.pacegallery.com",
        linkText:'verso.pacegallery.com.',
      },
      {
        name: "Step 4) After you have successfully connected your account or wallet, the page will reveal a button that allows you to claim your artwork."
      },
      {
        name: "Step 5) Proceed with the checkout process by entering your shipping information and billing details. Please see FAQs below for additional information regarding shipping and fulfillment."
      },
    ]
  },
  {
    id: 23,
    title: "Do I need to pay shipping in order to receive my print or t-shirt?",
    answer:
      `Yes, you will be responsible for shipping costs. Shipping costs are charged at the end of the Pace Verso claim process, and are automatically populated by our shipping carrier partners based on your shipping address.`,
  },
  {
    id: 24,
    title: "I live outside of the United States. Do I need to pay duties and import taxes?",
    answer:
      `If applicable, you will be responsible for paying duties and import taxes. These costs will be charged at the time of delivery. Please ensure that you provide reliable contact information during the check-out flow in case a customs officer needs to contact you regarding the package.`,
  },
  {
    id: 25,
    title: "I live outside of the United States. Can you ship my print or t-shirt to me?",
    answer:
      `Yes, we can ship prints and t-shirts to international countries. However some exclusions may apply for each project.`,
    answerTwo: "We are not able to ship to the following destinations: Cuba, Iran, North Korea, Sierra Leone, Palestinian Territories, South Sudan, Myanmar, Crimea Ukraine, Russia, Cote d’Ivoire, Democratic Republic of the Congo, Liberia, Lebanon, Sudan, Belarus, Iraq, Zimbabwe, and Syria. The destinations on this list are subject to change. Please reach out to our support team at",
    linkTwo: " mailto:paceversosupport@getmojito.com",
    ContinueTwo: " if you have any questions.",  
  },
  {
    id: 26,
    title: "How many times can a print or t-shirt be claimed?",
    answer:
      `Each print can only be claimed once. Each item’s claim status changes upon the completion of the claim process on the Pace Verso claim site.`,
  },
  {
    id: 27,
    title: "What happens if I try to claim a physical item without owning the NFT associated with the artwork?",
    answer:
      `Due to the token gating technology implemented in this claim website, you will be unable to claim a print or t-shirt unless you own the digital artwork associated with the print or t-shirt.`,
  },
  {
    id: 28,
    title: "How do I track the status of my print or t-shirt?",
    answer:
      `After you successfully claim your print, you will receive an order confirmation email from`,
    link: "mailto:verso@pacegallery.com",
    Continue: " Click on the “View your order” button in the email to open your order status page, which will contain the latest information about your order.",  
    linkText: " verso@pacegallery.com.",
  },
  {
    id: 29,
    title: "How long does print and t-shirt fulfillment take?",
    answer:
      `All orders submitted by the last day of each month will be shipped by approximately the 15th day of the following month.`,
    answerTwo: "Once your print or t-shirt has been shipped, you will receive a shipping confirmation email. This email will include a carrier tracking number for your shipment. If you have any questions about your order status, please reach out to our support team at",
    linkTwo: "mailto:paceversosupport@getmojito.com",
  },
  {
    id: 30,
    title: "My physical artwork or t-shirt arrived damaged. What should I do?",
    list: [
      {
        name: "Pace Verso and our printing partners, Brooklyn Editions for Ghost Forest Seedlings, and Customnia for CP1919: Sweeping Sun White 2023, will ship the physical components of your artwork carefully. In some cases, damage may still occur. If this is the case, please follow the steps below."
      },
      {
        name: "Receiver must not dispose of or destroy the damaged package and item. Receiver must send to Brooklyn Editions or Customnia all the required documents for the claims process:",
        list: ["A photo showing the damaged item and how it was packaged inside the packaging", 
        "A photo of the damaged item",
        "A photo of the packaging material used",
        "A close-up photo of the shipping label with tracking number (generally starts with 1Z)",
        "Two photos showing all six sides of the package (one showing top and two sides, another showing bottom and opposite sides)"]
      },
      {
        name: "For your Ghost Forest Seedlings print, photos must be sent to",
        link: "mailto:info@brooklyneditions.com",
        linkText: " info@brooklyneditions.com",
        Continue: " with the Subject Line including your Order # followed by ‘Damage Claims’."
      },
      {
        name: "For your CP1919: Sweeping Sun White 2023 t-shirt, please send an email to",
        link: "mailto:support@customnia.com",
        linkText: " support@customnia.com",
        Continue: ' with the subject line: "Support Pace Verso T-Shirt - [Your Order Number]." In the body of the email, please include your order details and a brief description of the damage. Attach the photographs to the email. These images will help us assess the extent of the damage and process your claim more efficiently.',
        ContinueNewLine: "<br />If you have any questions about this process. Please reach out to <a href={'mailto:verso@pacegallery.com'} target='_blank'>verso@pacegallery.com.</a>"
      },
    ]
  },
  {
    id: 31,
    title: "What is the claim period for my print or t-shirt?",
    answer:
      `The claim period is scheduled to close in six months, on April 16, 2024. Any updates will be communicated on this website.`,
  },

];
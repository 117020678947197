import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import parse from "html-react-parser"
import styles from "./faq.module.css";

interface faqListProps {
  qTitle?: string;
  qAnswer?: string;
  qLink?: string;
  qContinue?: string;
  qContinueNewLine?: string;
  qAnswerTwo?: string;
  qLinkTwo?: string;
  qContinueTwo?: string;
  qAnswerThree?: string;
  qLinkThree?: string;
  qLinkText?: string;
  qList?: {
    name?: string;
    list?: string[];
  }[]
}
export default function FaqList({
  qTitle,
  qAnswer,
  qLink,
  qContinue,
  qContinueNewLine,
  qAnswerTwo,
  qLinkTwo,
  qContinueTwo,
  qAnswerThree,
  qLinkThree,
  qLinkText,
  qList
}: faqListProps) {
  return (
    <div className="faqlist_wrap">
      <p className={styles.no_rec}>{/* Sorry, there are no results. */}</p>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<Icon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{fontFamily: 'untitled_sansregular_regular'}}>{qTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{fontFamily: 'untitled_sansregular_regular', paddingRight: {sm: '0px', lg: '80px', md: '40px', xl: '80px'}}}>
            {qAnswer}
            {qLink && (
              <>
                <a href={qLink} target="_blank">
                  {qLinkText}
                </a>
                {qContinue}
              </>
            )}
            {qAnswerTwo && <Typography sx={{marginTop: '20px', fontFamily: 'untitled_sansregular_regular'}}>{`${qAnswerTwo} `} {qLinkTwo && <a href={qLinkTwo} target="_blank">{"paceversosupport@getmojito.com."}</a>}{ qContinueTwo }</Typography>}
            {qAnswerThree && <Typography sx={{marginTop: '20px', fontFamily: 'untitled_sansregular_regular'}}>{`${qAnswerThree} `}
            {qLinkThree && (
              <>
                <a href={qLinkThree} target="_blank">
                  {qLinkText}
                </a>
                {qContinue}
              </>
            )}
            </Typography> }
            {qList && (
              <ol style={{ fontFamily: 'untitled_sansregular_regular' }}>
                {qList?.map((item: any) => (
                  <Typography style={{ marginBottom: '20px', fontFamily: 'untitled_sansregular_regular' }}>
                    {item?.name}
                    {item?.link && (
                      <>
                      <a href={item?.link} target="_blank">
                        { item?.linkText ? item?.linkText :'paceversosupport@getmojito.com.' }
                      </a>
                      { item?.Continue } <br />
                      { item?.ContinueNewLine && parse(String(item?.ContinueNewLine)) }
                      </>
                    )}
                    {item?.list && (
                      <>
                        { qTitle?.includes('email wallet to my crypto wallet') ? (
                          <ol style={{ paddingLeft: '40px' }}>
                            {item?.list?.map((data: string, ind: number) => (
                              <li style={{ margin: '10px' }} key={ind}>{parse(data)}</li>
                            ))}
                          </ol>
                        ) : (
                          <ul className={styles.faqPage} style={{ paddingLeft: '40px'}}>
                            {item?.list?.map((data: string, ind: number) => (
                              <li style={{ listStyleType: 'none', }} key={ind}>{parse(data)}</li>
                            ))}
                          </ul>
                        ) }
                      </>
                    )}
                  </Typography>
                ))}
              </ol>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div></div>
    </div>
  );
}

function Icon() {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="19" height="11" viewBox="0 0 19 11" fill="none">
    //       <path d="M1.07812 0.882812L9.33471 9.10209L17.5913 0.882812" stroke="black" stroke-width="1.4"/>
    // </svg>
    <i className="drop-icn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="19"
        viewBox="0 0 11 19"
        fill="none"
      >
        <path
          d="M1.21875 17.5859L9.43802 9.32935L1.21875 1.07276"
          stroke="black"
          stroke-width="1.4"
        />
      </svg>
    </i>
  );
}

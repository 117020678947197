import Link from "next/link";
import bcstyle from "./breadcrumbs.module.css";

interface BreadcrumbsProps {
  name?: string;
  noLink?: boolean;
}

const BreadcrumbsComponent = ({ name, noLink }: BreadcrumbsProps) => {
  return (
    <div className={bcstyle.main_div}>
      <span className={bcstyle.bcWrap}>
        <Link href="/">
          <span
            style={{ cursor: "pointer", height: 40, width: 40, display: "flex", justifyContent: 'center', alignItems: 'center', paddingLeft: "12px" }}
            className={`${bcstyle.homeicon} ${bcstyle.mr11}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
            >
              <path
                d="M5.75 11.25V16.875H1.25V6.75L8 1.125L14.75 6.75V16.875H10.25V11.25H5.75Z"
                stroke="#949494"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          {/* <img  src="/images/home.png" className={`img-responsive ${bcstyle.mr11}`}/>   */}
        </Link>
        <span
          className={`${bcstyle.dFlex} ${bcstyle.mr11}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
          >
            <path d="M1 13L7 7L1 1" stroke="#949494" stroke-width="1.4" />
          </svg>
          {/* <img  src="/images/right_arrow.png" className="img-responsive"/>   */}
        </span>
        {noLink ? (
          <span className={`${bcstyle.txtSize} ${bcstyle.mr11} `}>
            {name ? name : "Profile"}
          </span>
        ) : (
          <Link href="/profile">
            <span className={`${bcstyle.txtSize} ${bcstyle.mr11} cursor-p`}>
              {name ? name : "Profile"}
            </span>
          </Link>
        )}
      </span>
    </div>
  );
};

export default BreadcrumbsComponent;
